import { Injectable } from '@angular/core';
import { createClient, EntryCollection } from 'contentful';
import { environment } from '../../../environments/environment';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class ContentfulService {
  private readonly client = createClient({
    space: 'pj5d9edxa4so',
    accessToken: environment.contentful.contentfulToken,
    environment: environment.contentful.environment,
    host: 'cdn.contentful.com',
    timeout: 600000,
  });

  constructor(private readonly languageService: LanguageService) {}

  getHomePage(): Promise<EntryCollection<any>> {
    return this.client.getEntries({
      content_type: 'homePage',
      include: 3,
      locale: this.languageService.currentLanguageSubject.getValue(),
    });
  }

  getSectionById(id: string): Promise<EntryCollection<any>> {
    return this.client.getEntries({
      'sys.id': id,
    });
  }

  getSectionBySlug(slug: string): Promise<EntryCollection<any>> {
    return this.client.getEntries({
      content_type: slug,
    });
  }

  getLocales(): any {
    return this.client.getLocales();
  }

  getHeader(): Promise<EntryCollection<any>> {
    return this.client.getEntries({
      content_type: 'headerSection',
      include: 2,
      locale: this.languageService.currentLanguageSubject.getValue(),
    });
  }

  getFooter(): Promise<EntryCollection<any>> {
    return this.client.getEntries({
      content_type: 'footerSection',
      include: 2,
      locale: this.languageService.currentLanguageSubject.getValue(),
    });
  }

  getBrandPage(slug: string) {
    return this.client.getEntries({
      content_type: 'brandPage',
      'fields.slug': slug,
      include: 3,
      locale: this.languageService.currentLanguageSubject.getValue(),
    });
  }
}
