import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from '../../../environments/environment';
import { IBrandListResponse } from '../../standalone/grid-section/grid-section.interface';
import { IBrandResponse } from '../models/brand.interface';

@Injectable({
  providedIn: 'root',
})
export class RscService {
  private apiUrl = environment.apiUrl;
  constructor(private readonly _http: HttpClient) {}
  getBrands$(
    is_custom_platform = false,
    pagination: { limit: string; offset: string } = { limit: '12', offset: '0' },
  ): Observable<IBrandListResponse> {
    return this._http.get<IBrandListResponse>(
      `${this.apiUrl}/en/api/v3/public/brands/?limit=${pagination.limit}&offset=${pagination.offset}&is_custom_platform=${is_custom_platform}`,
    );
  }
  getBrand$(brandName: string): Observable<IBrandResponse> {
    return this._http.get<IBrandResponse>(
      `${this.apiUrl}/en/api/v3/public/brand/${brandName.toLocaleLowerCase().trim()}`,
    );
  }
}
