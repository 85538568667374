import { Component, Input } from '@angular/core';
import { IDivider } from '../../../core/models/section.interface';

@Component({
  selector: 'rsc-divider',
  standalone: true,
  imports: [],
  templateUrl: './divider.component.html',
  styleUrl: './divider.component.scss',
})
export class DividerComponent {
  @Input() element!: IDivider;
}
