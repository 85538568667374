import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ContentfulService } from '../../core/services/contentful.service';
import { ButtonComponent } from '../../shared/components/button/button.component';
import { LanguageSwitcherComponent } from '../../shared/components/language-switcher/language-switcher.component';

@Component({
  selector: 'rsc-header-section',
  standalone: true,
  imports: [CommonModule, ButtonComponent, LanguageSwitcherComponent],
  templateUrl: './header-section.component.html',
  styleUrl: './header-section.component.scss',
})
export class HeaderSectionComponent implements OnInit {
  headerData: any;
  constructor(private readonly contentfulService: ContentfulService) {}
  ngOnInit(): void {
    this.contentfulService
      .getHeader()
      .then((response) => {
        if (response && response.items && response.items.length > 0) {
          this.headerData = response.items[0].fields; // Assuming single header entry
          console.log('Header Data:', this.headerData);
        }
      })
      .catch((error) => {
        console.error('Error fetching header data:', error);
      });
  }
}
