import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IBrandResponse } from '../../core/models/brand.interface';
import { ISection } from '../../core/models/section.interface';
import { RscService } from '../../core/services/rsc-service.service';
import { BrandCardComponent } from '../../shared/components/brand-card/brand-card.component';
import { LinkButtonComponent } from '../../shared/components/link-button/link-button.component';
import { IBrandListResponse } from './grid-section.interface';

@Component({
  standalone: true,
  selector: 'rsc-grid-section',
  imports: [CommonModule, BrandCardComponent, LinkButtonComponent],
  templateUrl: './grid-section.component.html',
  styleUrls: ['./grid-section.component.scss'],
})
export class GridSectionComponent implements OnChanges {
  @Input() section!: ISection;
  @Input() data!: IBrandResponse;
  items: any[] = [];
  button = false;

  constructor(private readonly _rscService: RscService) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['section']) {
      if (this.data === undefined) {
        this.getBrands();
      }
    }

    if (changes['data'] && this.data !== null) {
      this.setInputData();
    }
  }

  getBrands() {
    this._rscService
      .getBrands$(this.section.customizedPlatform)
      .subscribe((data: IBrandListResponse) => {
        this.items = data.results;
        this.button = !!data.next;
      });
  }
  setInputData() {
    this.items = this.data.products;
    this.button = !!this.data.next;
  }
  redirectTo(url: string): void {
    window.open(url, '_blank');
  }
}
