import { Component } from '@angular/core';
import { RouterModule, RouterOutlet } from '@angular/router';
import { FooterSectionComponent } from './standalone/footer-section/footer-section.component';
import { HeaderSectionComponent } from './standalone/header-section/header-section.component';
@Component({
  selector: 'rsc-root',
  standalone: true,
  imports: [RouterModule, RouterOutlet, HeaderSectionComponent, FooterSectionComponent],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'rsc-standard';
}
