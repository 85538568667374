<div *ngIf="brandData()?.length">
  <div *ngFor="let section of brandData()">
    <ng-container [ngSwitch]="section.type">
      <rsc-grid-section
        *ngSwitchCase="'gridSection'"
        [section]="section"
        [data]="brandResponse()"></rsc-grid-section>
      <rsc-mid-section *ngSwitchCase="'imageSection'" [section]="section"></rsc-mid-section>
      <rsc-mid-section *ngSwitchCase="'midSection'" [section]="section"></rsc-mid-section>
    </ng-container>
  </div>
</div>
