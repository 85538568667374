import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  currentLanguageSubject = new BehaviorSubject<string>(this.getLocaleFromLocalStorage());

  constructor(private readonly router: Router) {
    this.currentLanguageSubject.subscribe((locale) => {
      router.navigate(['/' + locale]);
      this.setLocaleInLocalStorage(locale);
    });
  }

  setLocaleInLocalStorage(locale: string) {
    localStorage.setItem('locale', locale);
  }

  getLocaleFromLocalStorage(): string {
    return localStorage.getItem('locale') ?? 'en-US';
  }
}
