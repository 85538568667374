<div class="d-flex flex-column flex-md-row">
  <div
    class="itm d-flex justify-content-center"
    [class]="element.fields?.justifyContent"
    *ngFor="let element of element.fields?.elements"
    style="width: 100%">
    @switch (element.sys?.contentType.sys?.id) {
      @case ('buttonElement') {
        <rsc-button [button]="element"></rsc-button>
      }
      @case ('iconCard') {
        <rsc-icon-card [element]="element"></rsc-icon-card>
      }
      @case ('brandCard') {
        <rsc-brand-card [element]="element"></rsc-brand-card>
      }
      @case ('textCard') {
        <rsc-text-card [element]="element"></rsc-text-card>
      }
      @case ('linkButton') {
        <rsc-link-button [element]="element"></rsc-link-button>
      }
      @case ('dividerComponent') {
        <rsc-divider style="width: 100%" [element]="element"></rsc-divider>

        <!-- <rsc-divider [element]="element.divider"></rsc-divider> -->
      }
      @default {
        <div>No element founds for {{ element.sys?.contentType.sys?.id }}</div>
      }
    }
    <!-- @default {

     } -->

    <!-- <rsc-button
      *ngIf="element.sys?.contentType.sys?.id === 'buttonElement'"
      [button]="element"></rsc-button>
    <rsc-icon-card
      *ngIf="element.sys?.contentType.sys?.id == 'iconCard'"
      [element]="element"></rsc-icon-card>
    <rsc-brand-card
      *ngIf="element.sys?.contentType.sys?.id == 'brandCard'"
      [element]="element"></rsc-brand-card>
    <rsc-text-card
      *ngIf="element.sys?.contentType.sys?.id == 'textCard'"
      [element]="element"></rsc-text-card>
    <rsc-link-button
      *ngIf="element.sys?.contentType.sys?.id == 'linkButton'"
      [element]="element"></rsc-link-button>

    <rsc-divider
      *ngIf="element.sys?.contentType.sys?.id == 'dividerComponent'"
      [element]="element"></rsc-divider> -->
  </div>
</div>
