import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Component({
  selector: 'rsc-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './button.component.html',
  styleUrl: './button.component.scss',
})
export class ButtonComponent {
  @Input() button!: any;
  @Input() brandName?: string;
  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly router: Router,
  ) {}

  sanitizeUrl(url: string | undefined | null): SafeUrl | null {
    if (!url) {
      console.error('Invalid URL:', url);
      return null;
    }
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  redirectToUrl(url: string | undefined | null): void {
    if (this.brandName) {
      this.router.navigate([
        '/brand',
        this.brandName.toLocaleLowerCase().trim(),
        localStorage.getItem('locale') ?? 'en-US',
      ]);
      return;
    }
    const sanitizedUrl = this.sanitizeUrl(url);
    if (sanitizedUrl) {
      // Typecast SafeUrl to string because window.open requires a string
      window.open(url as string, '_blank', 'noopener,noreferrer');
    }
  }
}
