import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { BrandService } from '../../core/services/brand.service';
import { RscService } from '../../core/services/rsc-service.service';
import { GridSectionComponent } from '../../standalone/grid-section/grid-section.component';
import { MidSectionComponent } from '../../standalone/mid-section/mid-section.component';

@Component({
  standalone: true,
  imports: [CommonModule, RouterModule, GridSectionComponent, MidSectionComponent],
  selector: 'rsc-brand',
  templateUrl: './brand.component.html',
  styleUrls: ['./brand.component.scss'],
})
export class BrandComponent implements OnInit, OnDestroy {
  private readonly route = inject(ActivatedRoute);
  private readonly brandService = inject(BrandService);
  private readonly service = inject(RscService);

  // Signal to hold brand data
  brandData = signal<any | null>(null);
  brandResponse = signal<any | null>(null);

  brandSlug;

  constructor() {
    this.brandSlug = this.route.snapshot.paramMap.get('brandName');
    if (this.brandSlug) {
      this.brandService.getBrandPage(this.brandSlug).then((response) => {
        console.log('getBrandPage', response);
        this.brandData.set(response);
      });
    }
  }
  ngOnDestroy(): void {
    throw new Error('Method not implemented.');
  }
  ngOnInit(): void {
    if (this.brandSlug) this.getBrand();
  }
  getBrand() {
    if (this.brandSlug)
      this.service.getBrand$(this.brandSlug).subscribe({
        next: (response) => {
          this.brandResponse.set(response);
        },
      });
  }
}
