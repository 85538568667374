<div
  class="mid-section"
  [style.margin-left]="section.sideMargins || '0%'"
  [style.margin-right]="section.sideMargins || '0%'"
  [style.width]="calculateWidth(section.sideMargins)"
  [style.min-height]="renderedHeight"
  [style.background-image]="section.backgroundImage ? 'url(' + section.backgroundImage + ')' : null"
  [style.background-color]="section.backgroundColor || null"
  [style.padding-top]="section.topPadding ? section.topPadding + '%' : '0%'"
  [style.padding-bottom]="section.bottomPadding ? section.bottomPadding + '%' : '0%'"
  [style.padding-left]="section.sidePadding ? section.sidePadding + '%' : '0%'"
  [style.padding-right]="section.sidePadding ? section.sidePadding + '%' : '0%'">
  <!-- Section Title and Description -->
  <div
    class="overlay"
    [style.justify-content]="section.hAlignTitle || 'center'"
    [style.padding-left]="section.horizontalTitlePadding + '%' || '0%'"
    [style.padding-right]="section.horizontalTitlePadding + '%' || '0%'"
    [style.padding-top]="section.verticalTitlePadding ? section.verticalTitlePadding + '%' : '0%'"
    [style.padding-bottom]="
      section.verticalTitlePadding ? section.verticalTitlePadding + '%' : '0%'
    ">
    <h1 *ngIf="section.firstTitle" [style.color]="section.textColor || null">
      {{ section.title }}
    </h1>
    <h2 *ngIf="!section.firstTitle" [style.color]="section.textColor || null">
      {{ section.title }}
    </h2>
    <p [style.color]="section.textColor">{{ section.subtitle }} </p>
  </div>

  @if (section.subsections.length) {
    <rsc-subsection
      *ngFor="let subsection of section.subsections"
      [element]="subsection"></rsc-subsection>
  }
</div>
