<div
  class="brand-card justify-content-center d-flex flex-column align-items-center"
  [style.background-color]="element?.fields?.backgroundColor"
  [style.border-radius.px]="element?.fields?.borderRadius"
  [style]="element?.fields?.borderColor ? 'border-color: ' + element?.fields?.borderColor : null"
  [style.padding-top.px]="element?.fields?.paddingAround"
  [style.padding-bottom.px]="element?.fields?.paddingAround"
  [style.padding-right.px]="element?.fields?.paddingAround"
  [style.padding-left.px]="element?.fields?.paddingAround">
  <img [src]="brandData?.logo ?? brandData?.image" class="brand-card-image" alt="image" />
  <h3 class="brand-card-title">{{ brandData?.name ?? 'No Name' }}</h3>
  <rsc-button [button]="element?.fields?.cardButton" [brandName]="brandData?.name"></rsc-button>
</div>
