import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { ISection } from '../../core/models/section.interface';
import { SubsectionComponent } from '../../shared/components/subsection/subsection.component';

@Component({
  selector: 'rsc-mid-section',
  standalone: true,
  imports: [CommonModule, SubsectionComponent],

  templateUrl: './mid-section.component.html',
  styleUrls: ['./mid-section.component.scss'],
})
export class MidSectionComponent implements AfterViewInit {
  @Input() section!: ISection;
  renderedHeight: string | null = null;

  constructor(private readonly elRef: ElementRef) {}

  ngAfterViewInit(): void {
    console.log('pk', this.section);
    if (this.section.backgroundImage) {
      this.calculateRenderedHeight(this.section.backgroundImage);
    }
  }

  private calculateRenderedHeight(imageUrl: string): void {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      const aspectRatio = img.height / img.width;
      const containerWidth = this.elRef.nativeElement.offsetWidth;
      const calculatedHeight = containerWidth * aspectRatio;
      this.renderedHeight = `${calculatedHeight}px`;
    };
  }

  calculateWidth(sideMargins: string | undefined): string {
    if (!sideMargins) {
      return '100%';
    }
    const marginPercentage = parseFloat(sideMargins);
    const calculatedWidth = 100 - marginPercentage * 2;
    return `${calculatedWidth}%`;
  }

  mapHorizontalAlignmentTitle(hAlign: string | undefined, sidePadding: number): string {
    const paddingOffset = sidePadding;
    switch (hAlign) {
      case 'left':
        return `${paddingOffset + 10}%`;
      case 'center':
        return `50%`;
      case 'right':
        return `${100 - paddingOffset - 10}%`;
      default:
        return `${50}%`;
    }
  }
}
