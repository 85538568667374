<div
  class="footer-wrap d-flex flex-column justify-content-between"
  [style.background-color]="footerData?.backgroundColor || null"
  [style.padding-left]="footerData?.sidePadding + '%' || '0%'"
  [style.padding-right]="footerData?.sidePadding + '%' || '0%'"
  [style.padding-top]="footerData?.topPadding ? footerData?.topPadding + '%' : '0%'"
  [style.padding-bottom]="footerData?.bottomPadding ? footerData?.bottomPadding + '%' : '0%'"
  data-element_type="container">
  @if (footerData?.logoIcon) {
    <img
      [src]="logoUrl"
      alt="logo"
      class="logo"
      [style.height.px]="footerData?.logoIcon?.fields.imageHeight"
      [style.width.px]="footerData?.logoIcon?.fields.imageWidth" />
  }

  @if (footerData?.subsections) {
    <rsc-subsection
      *ngFor="let subsection of footerData.subsections"
      [element]="subsection"></rsc-subsection>
  }

  <!-- <div *ngIf="footerData?.textCards.length">
    <div class="d-flex flex-column flex-md-row justify-content-between first-row">
      <div *ngFor="let textCard of footerData?.textCards.slice(0, 3)">
        <rsc-text-card [element]="textCard"></rsc-text-card>
      </div>
    </div>

    <rsc-divider [element]="footerData?.divider"></rsc-divider>

    <div class="d-flex flex-column flex-md-row justify-content-between second-row">
      <div *ngFor="let textCard of footerData?.textCards.slice(3, 6)">
        <rsc-text-card [element]="textCard"></rsc-text-card>
      </div>
    </div>
  </div>

  <rsc-divider [element]="footerData?.divider"></rsc-divider> -->

  <div
    class="elementor-element elementor-element-a14afb1 elementor-widget elementor-widget-text-editor"
    data-id="a14afb1"
    data-element_type="widget"
    data-widget_type="text-editor.default">
    <div class="elementor-widget-container">
      {{ '@2020-2024 Product DNA ALL RIGHTS RESERVED' }}
    </div>
  </div>
</div>
