import { Injectable } from '@angular/core';
import { ISection } from '../models/section.interface';
import { ContentfulService } from './contentful.service';
import { PageService } from './page.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private readonly contentfulService: ContentfulService,
    private readonly pageService: PageService,
  ) {}

  // Fetch homepage data and map it into Section objects
  async getHomePage(): Promise<ISection[]> {
    const response = await this.contentfulService.getHomePage();

    if (!response?.items?.length) {
      throw new Error('No homepage data found');
    }

    return this.pageService.mapPageData(response);
  }
}
