export const environment = {
  production: false,
  apiUrl: process.env['API_URL'] || 'https://snowflake-backoffice.respect-code.org',
  baseApiUrl: process.env['BASE_API_URL'] || 'https://snowflake-backoffice.respect-code.org',
  assetPath: '../assets/',
  mapboxToken: process.env['MAPBOX_TOKEN'] || '',
  mapboxStyle: process.env['MAPBOX_STYLE'] || '',
  enableSentry: process.env['ENABLE_SENTRY'] === 'true',
  contentful: {
    contentfulToken: process.env['CONTENTFUL_TOKEN'] || '',
    environment: process.env['CONTENTFUL_ENV'] || 'master',
  },
};
