import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

import { ISubSectionObject } from '../../../core/models/subsection.interface';
import { BrandCardComponent } from '../brand-card/brand-card.component';
import { ButtonComponent } from '../button/button.component';
import { DividerComponent } from '../divider/divider.component';
import { IconCardComponent } from '../icon-card/icon-card.component';
import { LinkButtonComponent } from '../link-button/link-button.component';
import { TextCardComponent } from '../text-card/text-card.component';

@Component({
  selector: 'rsc-subsection',
  standalone: true,
  imports: [
    ButtonComponent,
    IconCardComponent,
    CommonModule,
    BrandCardComponent,
    TextCardComponent,
    LinkButtonComponent,
    DividerComponent,
  ],
  templateUrl: './subsection.component.html',
  styleUrl: './subsection.component.scss',
})
export class SubsectionComponent {
  @Input() element!: ISubSectionObject;
}
